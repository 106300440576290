import { Injectable } from '@angular/core'
import { Observable, of, Subject, tap } from 'rxjs'
import { CurrentUserService } from '@app/generated'

@Injectable({
	providedIn: 'root',
})
export class ContractorInnerService {
	refreshOrdersPreviewPage$: Subject<boolean> = new Subject<boolean>()
	private hasContractors?: boolean

	constructor(private currentUserService: CurrentUserService) {}

	currentUserHasContractors(): Observable<boolean> {
		if (this.hasContractors !== undefined) {
			return of(this.hasContractors)
		} else {
			return this.currentUserService.hasContractors().pipe(
				tap((hasContractors: boolean) => {
					this.hasContractors = hasContractors
				}),
			)
		}
	}
}
