import { Injectable } from '@angular/core'
import { Observable, of, Subject, tap } from 'rxjs'
import { CurrentUserService } from '@app/generated'

@Injectable({
	providedIn: 'root',
})
export class OrdersInnerService {
	refreshOrdersPreviewPage$: Subject<boolean> = new Subject<boolean>()
	private hasUsers?: boolean
	private costSettlemets = new Subject<boolean>()

	refreshCostSettlements$ = this.costSettlemets.asObservable()

	constructor(private currentUserService: CurrentUserService) {}

	/**
	 * Determines if the current user has any orders.
	 *
	 * This method returns an Observable that emits a boolean value indicating whether the current user has orders or not.
	 * It first checks if the value of `hasUsers` property is defined. If it is, the value is directly returned through the `of` function.
	 * Otherwise, it calls the `hasOrders` method of the `currentUserService` and stores the response in the `hasUsers` property.
	 *
	 * @return {Observable<boolean>} An Observable that emits a boolean value indicating if the current user has orders.
	 */
	currentUserHasOrders(): Observable<boolean> {
		if (this.hasUsers !== undefined) {
			return of(this.hasUsers)
		}
		return this.currentUserService.hasOrders().pipe(
			tap(response => {
				this.hasUsers = response
			}),
		)
	}

	updateState(newState: boolean) {
		setTimeout(() => {
			this.costSettlemets.next(newState)
		}, 500)
	}
}
